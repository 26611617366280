<template>
  <v-app>
    <v-container fill-height>
      <v-row>
        <v-col
          cols="12"
          class="notfound"
        >
          <img
            src="@/assets/img/errors/404.svg"
            alt="Not Found"
          >
          <h1>{{ $t('global:notFound') }}</h1>
          <v-btn
            rounded
            dark
            class="btn--main"
            @click="$router.push('/')"
          >
            {{ $t('global:return') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
    i18nOptions: { namespaces: ['global'] },
    data: () => ({}),
}
</script>
